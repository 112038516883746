import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import AddProgression from "./AddProgression";
import { MasterService } from "../../services";
import { Role } from "../../config/constant";

const ProgressionList = (props) => {
    const [DeleteProgressionPopup, setDeleteProgressionPopup] = useState(false);
    const [ModifyStatusPopup, setModifyStatusPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [tempErrors, setTempErrors] = useState({});
    const [config, setConfig] = useState({});
    const [selected_index, setSelectedIndex] = useState(0);
    const [progression_list, setProgressionList] = useState([]);
    const [expired_progression_list, setExpiredProgressionList] = useState([]);
    const [progressionAllData, setProgressionAllData] = useState([]);

    useEffect(() => {
        getProgressionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModalpopup = (name, index) => {
        if (name === "DeleteProgressionPopup") {
            setDeleteProgressionPopup(true);
        } else if (name === "ModifyStatusPopup") {
            setModifyStatusPopup(true);
        }
        setSelectedIndex(index);
    };

    const hideModalpopup = (name) => {
        if (name === "DeleteProgressionPopup") {
            setDeleteProgressionPopup(false);
        } else if (name === "ModifyStatusPopup") {
            setModifyStatusPopup(false);
        }
        setSelectedIndex(0);
    };

    const handleDeleteYes = () => {
        let tempProgressionData = [...progression_list];
        let element = tempProgressionData[selected_index];
        if (element.id) {
            updateProgressionStatus(element, 4);
        }
        tempProgressionData.splice(selected_index, 1);
        setProgressionList(tempProgressionData);
        hideModalpopup("DeleteProgressionPopup");
    };

    const getProgressionList = async () => {
        setLoading(true);
        let tempConfig = await getProgressionConfig();
        await MasterService.post("/sfa/user/get-progression-list", { data_status: parseInt(activeTab) === 1 ? activeTab : 0 })
            .then(function (response) {
                if (response.data.status === 200) {
                    let progression_data = response.data.data.progression_data;

                    let final_progression_data = progression_data.map((obj) => {
                        return {
                            ...obj,
                            is_expired: parseInt(obj.status) === 0 ? true : false,
                            financier_list: tempConfig?.financier_list?.length
                                ? tempConfig.financier_list.filter((item) => obj.financier_list.split(",").includes(item.id.toString()))
                                : [],
                        };
                    });
                    setProgressionAllData(final_progression_data);
                    let temp_active_progression_list = final_progression_data.filter((e) => parseInt(e.status) === 1 || parseInt(e.status) === 2);
                    let temp_expired_progression_list = final_progression_data.filter((e) => parseInt(e.status) === 0);
                    setProgressionList(temp_active_progression_list);
                    setExpiredProgressionList(temp_expired_progression_list);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                console.log(response);
                setLoading(false);
            });
    };

    const getProgressionConfig = async () => {
        let temp_config = {};
        await MasterService.post("/sfa/user/get-progression-config", { restrict_role: true })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfig(response.data.data);
                    temp_config = response.data.data;
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) { });
        return temp_config;
    };

    const getConfigList = async (role_id, tempProgressionData, index) => {
        setLoading(true);
        let params = { role_id: role_id, is_progression: true };
        if (role_id === Role["BM"]) {
            params.send_bm_grading = true;
        }
        MasterService.post("/sfa/user/get-config", params)
            .then(function (response) {
                if (response.data.status === 200) {
                    let temp_config = { ...config };
                    let user_level_tagging = [];
                    if (tempProgressionData[index]["parameter_type"] === "average" && role_id === Role["BM"]) {
                        user_level_tagging = response.data.data.bm_grading.map((obj) => {
                            return {
                                ...obj,
                                operator_points: "",
                                average_points: "",
                            };
                        });
                    } else {
                        user_level_tagging = response.data.data.tagging_roles.map((obj) => {
                            return {
                                ...obj,
                                operator: "",
                                points: "",
                            };
                        });
                    }

                    tempProgressionData[index].user_level_tagging = user_level_tagging;
                    temp_config.business_line = response.data.data.business_line;
                    setProgressionList(tempProgressionData);
                    setConfig(temp_config);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false);
            });
    };

    const handleChange = async (name, option, index) => {
        let tempProgressionData = [...progression_list];
        let element = tempProgressionData[index];
        if (name === "user_level_tagging" || name === "description" || name === "status") {
            if (name === "status") {
                await updateProgressionStatus(element, option);
                hideModalpopup("ModifyStatusPopup");
            }
            tempProgressionData[index][name] = option;
        } else if (name === "role_id") {
            tempProgressionData[index][name] = parseInt(option.value);
            if (tempProgressionData[index]["parameter_type"] !== "") {
                tempProgressionData[index]["parameter_type"] = "";
                tempProgressionData[index]["business_line"] = "";
                tempProgressionData[index]["month"] = "";
            }
        } else if (name === "parameter_type") {
            if (tempProgressionData[index]["role_id"] === Role["BM"]) {
                tempProgressionData[index][name] = "average";
            } else {
                tempProgressionData[index][name] = option.id;
            }
        } else {
            if (name === "month") {
                tempProgressionData[index].year = option.year;
            }
            tempProgressionData[index][name] = option.value;
        }
        if (name === "financier_list") {
            tempProgressionData[index][name] = option;
        }

        if (name === "application_type") {
            tempProgressionData[index][name] = option;
        }

        if (name === "parameter_type") {
            setLoading(true);
            await getConfigList(tempProgressionData[index]["role_id"], tempProgressionData, index);
        } else {
            setProgressionList(tempProgressionData);
        }
    };

    const updateProgressionStatus = async (element, value) => {
        setLoading(true);
        let params = {
            id: element.id,
            status: value,
        };
        MasterService.post("/sfa/user/update-progression-data", params)
            .then(function (response) {
                if (response.data.status === 200) {
                    getProgressionList();
                    toast.success(response.data.message);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false);
            });
    };

    const checkExistingData = async (element, index) => {
        setLoading(true);
        let data_existing_status = false;
        await MasterService.post("/sfa/user/check-existing-progression", element)
            .then(function (response) {
                if (response.data.status === 200) {
                    if (response.data.data.data_exist) {
                        data_existing_status = true;
                    }
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false);
            });
        return data_existing_status;
    };

    const validUpdateForm = (data, i) => {
        let formIsValid = true;
        let tempErrors = {};
        if (!data["role_id"]) {
            formIsValid = false;
            tempErrors["user_role_" + i] = "User Role is required";
        }
        if (!data["parameter_type"]) {
            formIsValid = false;
            tempErrors["parameter_type_" + i] = "Parameter Type is required";
        }
        if (!data["business_line"]) {
            formIsValid = false;
            tempErrors["business_line_" + i] = "Business Line is required";
        }
        if (!data["month"]) {
            formIsValid = false;
            tempErrors["month_" + i] = "Month is required";
        }

        if (data.role_id !== Role["BM"] && !data["financier_list"]) {
            formIsValid = false;
            tempErrors["financier_list_" + i] = "Financiers is/are required";
        }

        if (data.role_id !== Role["BM"] && (!data["application_type"] || (Array.isArray(data["application_type"]) && data["application_type"].length === 0))) {
            formIsValid = false;
            tempErrors["application_type_" + i] = "Application Type is required";
        }

        if (data.role_id !== Role["BM"] && !data["duration"]) {
            formIsValid = false;
            tempErrors["duration_" + i] = "Duration is required";
        }

        if (data.user_level_tagging && data.user_level_tagging.length) {
            if (data.parameter_type === "average" && data["role_id"] === Role["BM"]) {
                data.user_level_tagging.forEach((element, index) => {
                    if (!element["operator_points"]) {
                        formIsValid = false;
                        tempErrors["operator_points_" + i + "_" + index] = "Operator is required";
                    }
                    if (!element["average_points"]) {
                        formIsValid = false;
                        tempErrors["average_points_" + i + "_" + index] = "Average Points are required";
                    }
                });
            } else {
                data.user_level_tagging.forEach((element, index) => {
                    if (!element["points"]) {
                        formIsValid = false;
                        tempErrors["points_" + i + "_" + index] = "Points are required";
                    }
                    if (!element["operator"]) {
                        formIsValid = false;
                        tempErrors["operator_" + i + "_" + index] = "Operator is required";
                    }
                });
            }
        }
        setTempErrors(tempErrors);
        return formIsValid;
    };

    const handleUpdate = async (data, index) => {
        if (validUpdateForm(data, index)) {
            let data_exist = await checkExistingData(data, index);
            if (data_exist) {
                toast.error("Data Already Exist With Same Role, Business Line And Month");
                setLoading(false);
            } else {
                let user_level_tagging_modified = [];
                if (data.parameter_type === "average" && data.role_id === Role["BM"]) {
                    user_level_tagging_modified = data.user_level_tagging.map((obj) => ({
                        user_tagging_id: obj.value,
                        average_points: obj.average_points,
                        operator_points: obj.operator_points.value,
                        status: "1",
                    }));
                } else {
                    user_level_tagging_modified = data.user_level_tagging.map((obj) => ({
                        user_tagging_id: obj.value,
                        points: obj.points,
                        operator: obj.operator.value,
                        status: "1",
                    }));
                }

                let financier_list = data?.financier_list?.filter((item) => item.value !== "*")?.map((item) => item.id) || [];
                let params = {
                    id: data.id,
                    role_id: data.role_id,
                    business_line: data.business_line,
                    parameter_type: data.parameter_type,
                    month: data.month,
                    year: data.year,
                    financier_list: financier_list.join(","),
                    description: data.description,
                    user_level_tagging: user_level_tagging_modified,
                    status: data.status,
                    application_type:
                        data?.application_type
                            ?.filter((item) => item.value !== "*")
                            ?.map((item) => item.value)
                            ?.join(",") || "",
                    duration: data.duration,
                };
                if (data.id) {
                    params.id = data.id;
                }
                MasterService.post("sfa/user/save-progression-data", params)
                    .then(function (response) {
                        if (response.data.status === 200) {
                            toast.success(response.data.message);
                            getProgressionList();
                            setLoading(false);
                        } else {
                            setLoading(false);
                            toast.error(response.data.message);
                        }
                    })
                    .catch(function (response) {
                        setLoading(false);
                        toast.error(response);
                    });
            }
        }
    };

    const handleCancel = (element, index) => {
        let tempProgressionData = [...progression_list];
        if (element.id) {
            getProgressionList();
        } else {
            tempProgressionData.splice(index, 1);
            setProgressionList(tempProgressionData);
        }
    };

    const handleAddProgression = () => {
        let tempProgressionData = [...progression_list];
        let tempAllProgressionData = [...progressionAllData];
        let progression_data = {
            role_id: "",
            application_type: [],
            business_line: "",
            month: "",
            description: "",
            status: 1,
            preExpandedTabs: 0,
        };

        tempProgressionData.unshift(progression_data);
        setProgressionList(tempProgressionData);
        setProgressionAllData(tempAllProgressionData);
    };

    const showExpandedTabUuidTabs = (uuid, id) => {
        let tempProgressionData = [...progressionAllData];

        let temp_data = tempProgressionData.findIndex((e) => e.id === id);
        tempProgressionData[temp_data].preExpandedTabs = uuid;
        setProgressionAllData(tempProgressionData);
    };

    return (
        <React.Fragment>
            <div className={loading ? "container-fluid loading" : "container-fluid"}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>Add Journey</h1>
                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item
                                        onClick={() => {
                                            setActiveTab(1);
                                        }}
                                    >
                                        <Nav.Link eventKey="first">Active Journey</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                        onClick={() => {
                                            setActiveTab(2);
                                        }}
                                    >
                                        <Nav.Link eventKey="second">Expired Journey</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </div>
                        {activeTab === 1 && (
                            <button
                                onClick={() => {
                                    handleAddProgression();
                                }}
                                className="btn-line btn-add-incentive"
                            >
                                <i className="ic-add"></i>
                                <span>Add Journey</span>
                            </button>
                        )}
                    </div>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            {progression_list.length > 0 && Object.keys(config).length > 0 ? (
                                <AddProgression
                                    activeTab={activeTab}
                                    progression_list={progression_list}
                                    tempErrors={tempErrors}
                                    handleChange={handleChange}
                                    handleUpdate={handleUpdate}
                                    handleCancel={handleCancel}
                                    config={config}
                                    showExpandedTabUuid={showExpandedTabUuidTabs}
                                    showModalpopup={showModalpopup}
                                />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <span>No Journey Found</span>
                                        <button
                                            className="btn-primary"
                                            onClick={() => {
                                                handleAddProgression();
                                            }}
                                        >
                                            Add Journey
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            {expired_progression_list.length > 0 && Object.keys(config).length > 0 ? (
                                <AddProgression
                                    activeTab={activeTab}
                                    progression_list={expired_progression_list}
                                    tempErrors={tempErrors}
                                    handleChange={handleChange}
                                    handleUpdate={handleUpdate}
                                    handleCancel={handleCancel}
                                    config={config}
                                    showExpandedTabUuid={showExpandedTabUuidTabs}
                                    showModalpopup={showModalpopup}
                                />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <span>No Expired Journey Found</span>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <div className="delete-incentive-popup model-popup-outer">
                    <Modal
                        show={DeleteProgressionPopup}
                        handleClose={() => {
                            hideModalpopup("DeleteProgressionPopup");
                        }}
                    >
                        <div className="modal-header"></div>
                        <div className="modal-body">
                            <div className="delete-popup-txt">
                                <span>Are you sure you want to delete this progression</span>
                                <div className="yes-no-btn">
                                    <button
                                        onClick={() => {
                                            hideModalpopup("DeleteProgressionPopup");
                                        }}
                                        className="btn-line"
                                    >
                                        No
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleDeleteYes();
                                        }}
                                        className="btn-primary"
                                    >
                                        yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                {/* Modal for status confirmation of incentive */}
                <div className="delete-incentive-popup model-popup-outer">
                    <Modal
                        show={ModifyStatusPopup}
                        handleClose={() => {
                            hideModalpopup("ModifyStatusPopup");
                        }}
                    >
                        <div className="modal-header"></div>
                        <div className="modal-body">
                            <div className="delete-popup-txt">
                                <span>Are you sure you want to inactive this progression</span>
                                <div className="yes-no-btn">
                                    <button
                                        onClick={() => {
                                            hideModalpopup("ModifyStatusPopup");
                                        }}
                                        className="btn-line"
                                    >
                                        No
                                    </button>
                                    <button
                                        onClick={() => {
                                            handleChange("status", 2, selected_index);
                                        }}
                                        className="btn-primary"
                                    >
                                        yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default ProgressionList;
