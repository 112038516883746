import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
// import { MasterService } from '../../services';//removed warning
import InputField from '../common/Input';
import { ACCOUNT_MANAGER_BUSINESS_LINE, RoleId, TENANT } from '../../config/constant';
import WithRights from '../common/WithRights';
const SalesMappedUser = (props) => {
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenId, setTipOpenId] = useState(0);
    const [search_text, setSearchText] = useState('')
    const [assigned_executive_data, setAssignedExecutiveData] = useState(null)
    const [unassigned_executive_data, setUnassignedExecutiveData] = useState(null)

    useEffect(() => {
        setAssignedExecutiveData(props.assigned_executive)
        setUnassignedExecutiveData(props.unAssigned_Executive)
        setTipOpenId('')
    }, [props.assigned_executive, props.unAssigned_Executive])

    useEffect(() => {
        setSearchText("")
    }, [props.search_reset])

    const toggleTip = (user_id, state) => {
        if (state === 'close') {
            user_id = ''
        }
        setTipOpen(!tipOpen)
        setTipOpenId(user_id)
    }

    const handleInputChange = async (target_name, type, event) => {
        let { value, name, checked } = event.target
        let temp_assigned_executive_data = [...assigned_executive_data]
        if (target_name === "search_text") {
            setSearchText(value)
            if (value.length > 2) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: value }
                await props.handleUnAssignedExecutive(params, assigned_executive_data)
            }
            if (value.length == 0) {
                let params = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, search_text: "" }
                await props.handleUnAssignedExecutive(params, assigned_executive_data)
            }
        }
        if (type === "checkbox") {
            let selected_account_manager = temp_assigned_executive_data.filter(e => e.is_account_manager == 1)
            if (checked) {
                if (TENANT=="id" && selected_account_manager.length) {
                    toast.error("Only one BRO can be an Account manager for a single dealer")
                } else {
                    let change_account_manager_index = temp_assigned_executive_data.findIndex(e => e.user_id == name)
                    temp_assigned_executive_data[change_account_manager_index].is_account_manager = 1
                    setAssignedExecutiveData(temp_assigned_executive_data)
                }
            } else {
                let change_account_manager_index = temp_assigned_executive_data.findIndex(e => e.user_id == name)
                temp_assigned_executive_data[change_account_manager_index].is_account_manager = 0
                setAssignedExecutiveData(temp_assigned_executive_data)
            }
        }
    }

    const handleClick = (element, index, type) => {
        let temp_unassigned_executive_data = [...unassigned_executive_data]
        let temp_assigned_executive_data = [...assigned_executive_data]
        if (type === 'unassigned') {
            let bm_data = temp_assigned_executive_data.filter(e => e.role_name === RoleId["52"])
            let current_bm_data = element.role_name == RoleId["52"] ? 1 : 0
            if ((bm_data && bm_data.length) || (current_bm_data && temp_assigned_executive_data.length)) {
                toast.error("Dealer cannot be mapped to any users when mapped to BM")
            } else {
                element.is_account_manager = 0
                temp_unassigned_executive_data = temp_unassigned_executive_data.filter((e) => e.user_id != element.user_id)
                temp_assigned_executive_data.unshift(element)
            }
        }
        if (type === 'assigned') {
            temp_assigned_executive_data = temp_assigned_executive_data.filter((e) => e.user_id != element.user_id)
            temp_unassigned_executive_data.unshift(element)

        }
        setAssignedExecutiveData(temp_assigned_executive_data)
        setUnassignedExecutiveData(temp_unassigned_executive_data)
    }

    const handleSubmit = async () => {
        let selected_account_manager = assigned_executive_data.filter(e => e.is_account_manager == 1)
        let bro_data = assigned_executive_data.filter(e => e.role_name === "BRO")
        let account_manager_id = (TENANT=="id") && selected_account_manager.length ? selected_account_manager[0].user_id : 0
        let assigned_user_id = assigned_executive_data.map(({ user_id }) => user_id)
        if (TENANT=="id" && bro_data && bro_data.length === 1) {
            let user_qualified_for_account_manager = bro_data.filter(e => e.business_line === ACCOUNT_MANAGER_BUSINESS_LINE)
            if (user_qualified_for_account_manager && user_qualified_for_account_manager.length === 1 && !user_qualified_for_account_manager[0].is_account_manager) {
                alert("Only one BRO mapped, BRO will be made Account manager");
            }
        }

        props.updateAssignExecutive(assigned_user_id, account_manager_id)
        setSearchText("")
    }

    return (
        <React.Fragment>
            <div className='sales-users-left-panel'>
                <h3>Sales Users</h3>
                <div className='sales-mapped-bg'>
                    <fieldset class="search-bx">
                        <div class="material">
                            <InputField type="text" name="search_text" id="search_text" placeholder="Search Agent,BRO or BM" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i class="ic-search"></i>
                        </div>
                    </fieldset>
                    <div className='sales-user-list'>
                        <ul>
                            {unassigned_executive_data && unassigned_executive_data.length > 0 && unassigned_executive_data.map((element, index) => (
                                <li key={index}>
                                    <label>{element.name + " - " + element.user_id + (element.role_name ? " (" + element.role_name + ")" : "") + (element.reporting_manager_name ? (" ," + element.reporting_manager_name) : "") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")}</label>
                                    {element.dealercount > 0 &&
                                        <span className='mapped_user_toggle'>{element.dealercount + " Mapped Dealers"}
                                            <i onClick={() => toggleTip(element.user_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                            <span title="" className="tooltio-ic">

                                            </span>

                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button" onClick={() => toggleTip(element.user_id, 'close')}><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                    <li className='popup-list' key={dealer_index}>{dealer_element.dealer_name + " (GCD-" + dealer_element.id + ")"}<span className='account-manager-span'>{TENANT=="id" && dealer_element.user_id == element.user_id && dealer_element.is_account_manager ? " Account Manager" : ""}</span></li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                isOpen={(element.user_id === tipOpenId) && tipOpen ? true : false}
                                                tagName="span"
                                                direction="down"
                                                className="tootip-outer"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </span>
                                    }
                                    <i onClick={(e) => handleClick(element, index, 'unassigned')} className='ic-arrow_forward listclose-ic'></i>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className='mapped-users-right-panel'>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list'>
                        <ul>
                            {assigned_executive_data && assigned_executive_data.length > 0 && assigned_executive_data.map((element, index) => (
                                <li key={index}>
                                    {/* {element.role_name == "BRO" ? */}
                                    <div className={TENANT == "id" && element.role_name == "BRO" ? 'd-flex' : "d-flex remove_visibility"}>
                                        <WithRights rightKey="update_dealer_mapping"
                                            fallback={
                                                <div className="custom-control custom-checkbox m-md-r">
                                                    <input
                                                        id={`${element.user_id}`}
                                                        type="checkbox"
                                                        name={`${element.user_id}`}
                                                        className="custom-control-input"
                                                        checked={element.is_account_manager ? true : false}
                                                        disabled={true}
                                                    />
                                                    <label htmlFor={`${element.user_id}`} className="custom-control-label display-none"></label>
                                                </div>
                                            }


                                        >
                                            <div className="custom-control custom-checkbox m-md-r">
                                                <input
                                                id={`${element.user_id}`}
                                                type="checkbox"
                                                name={`${element.user_id}`}
                                                onChange={handleInputChange.bind(null, `${element.user_id}`, 'checkbox')}
                                                className="custom-control-input"
                                                checked={element.is_account_manager ? true : false}
                                            />
                                            <label htmlFor={`${element.user_id}`} className="custom-control-label display-none"></label>
                                        </div>

                                        </WithRights>
                                        <label className='account-manager-checkbox-text'>{element.name + " - " + element.user_id + (element.role_name ? " (" + element.role_name + ")" : "") + (element.reporting_manager_name ? (" ," + element.reporting_manager_name) : "") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")}
                                                {element.assigned_dealer_data.length > 0 &&
                                                    <span className='mapped_user_toggle'>{element.assigned_dealer_data.length + " Mapped Dealers"}
                                                        {/* <i onClick={() => toggleTip(element.user_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                                        <span title="" className="tooltio-ic">

                                                        </span>
                                                        <Tooltip
                                                            content={(
                                                                <div className="controlled-example tooltip-main">
                                                                    <div className="tooltip-data">
                                                                        <span className="controlled-example_close-button" onClick={() => toggleTip(element.user_id, 'close')}><i className="ic-clearclose"></i></span>
                                                                        <ul className="user-detail">
                                                                            {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                                <li className='popup-list' key={dealer_index}>{dealer_element.dealer_name + " (GCD-" + dealer_element.id + ")"}<span className='account-manager-span'>{dealer_element.user_id == element.user_id && dealer_element.is_account_manager ? " Account Manager" : ""}</span></li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            isOpen={(element.user_id === tipOpenId) && tipOpen ? true : false}
                                                            tagName="span"
                                                            direction="down"
                                                            className="tootip-outer"
                                                            forceDirection
                                                        >
                                                        </Tooltip> */}
                                                        <Tooltip
                                                        useContext
                                                        html={(
                                                            <div className="controlled-example tooltip-main">
                                                                <div className="tooltip-data tootip-outer">
                                                                    <ul className="user-detail">
                                                                        {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                            <li className='popup-list' key={dealer_index}>{dealer_element.dealer_name + " (GCD-" + dealer_element.id + ")"}<span className='account-manager-span dealer-am-list'>{TENANT=="id" && dealer_element.user_id == element.user_id && dealer_element.is_account_manager ? " Account Manager" : ""}</span></li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                        className="tootip-outer"
                                                        // open="true"
                                                        theme="light"
                                                        size="regular"
                                                        position="bottom"
                                                        trigger="click"
                                                    >
                                                        <span title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                                        </span>
                                                    </Tooltip>
                                                    </span>
                                                }
                                            </label>
                                        </div>
                                        {/* : */}
                                        {/* <div className='mapped_sales_users_listing'>
                                            <label>{element.name + " - " + element.user_id + (element.role_name ? " (" + element.role_name + ")" : "") + (element.reporting_manager_name ? (" ," + element.reporting_manager_name) : "") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")}
                                                {element.assigned_dealer_data.length > 0 &&
                                                    <span className='mapped_user_toggle'>{element.assigned_dealer_data.length + " Mapped Dealers"}
                                                        <i onClick={() => toggleTip(element.user_id, 'open')} className='ic-info-icon1 m-sm-l'></i>
                                                        <span title="" className="tooltio-ic">

                                                        </span>
                                                        <Tooltip
                                                            content={(
                                                                <div className="controlled-example tooltip-main">
                                                                    <div className="tooltip-data">
                                                                        <span className="controlled-example_close-button" onClick={() => toggleTip(element.user_id, 'close')}><i className="ic-clearclose"></i></span>
                                                                        <ul className="user-detail">
                                                                            {element.assigned_dealer_data?.length && element.assigned_dealer_data.map((dealer_element, dealer_index) => (
                                                                                <li className='popup-list' key={dealer_index}>{dealer_element.dealer_name + " (GCD-" + dealer_element.id + ")"}<span className='account-manager-span'>{dealer_element.user_id == element.user_id && dealer_element.is_account_manager ? " Account Manager" : ""}</span></li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            isOpen={(element.user_id === tipOpenId) && tipOpen ? true : false}
                                                            tagName="span"
                                                            direction="down"
                                                            className="tootip-outer"
                                                            forceDirection
                                                        >
                                                        </Tooltip>
                                                    </span>
                                                }
                                            </label>

                                        </div>
                                    } */}
                                    <i onClick={(e) => handleClick(element, index, 'assigned')} className='ic-clearclose listclose-ic'></i>
                                </li>
                            ))}

                        </ul>                     
                        <div className='btn-submit'>
                            <WithRights rightKey="update_dealer_mapping">
                            <button onClick={() => handleSubmit()} className='btn-primary'>  Submit </button>
                            {(TENANT=="id") &&
                            <span className='m-md-l select-account-manager'><i class="ic-info-icon1 m-xs-r"></i>
                                Select a BRO to assign as Account Manager</span>
                            }
                            </WithRights>
                        </div>                   
                    </div>
                </div>
            </div>
            {(TENANT == "id") &&
            <div className='mapped-users-right-panel' style={{ "display": 'none' }}>
                <h3>Mapped Sales Users</h3>
                <div className='sales-mapped-bg'>

                    <div className='sales-user-list no-data-txt'>
                        <ul>
                            <li>Move Agent, BRO here</li>
                        </ul>
                    </div>
                </div>
            </div>
        }
        </React.Fragment>
    )
    // }
}
export default SalesMappedUser;