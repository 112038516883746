import React, { useEffect } from "react";
import Select from "react-select";

const AppsSubmittedCard = (props) => {
    useEffect(() => { }, [props.element.user_level_tagging, props.tempErrors, props.config]);

    const handleChangeSelect = (option, key, index, index1) => {
        let temp_user_level_tagging = props.element.user_level_tagging;
        temp_user_level_tagging[index1][key] = option;
        props.onChange("user_level_tagging", temp_user_level_tagging, index);
    };

    const handleChange = (event, name, index, index1) => {
        let { value } = event.target;
        let temp_user_level_tagging = props.element.user_level_tagging;
        temp_user_level_tagging[index1][name] = value;
        if (name === "points") {
            value = value.toString();
            if (value && value.length < 15) {
                props.onChange("user_level_tagging", temp_user_level_tagging, index);
            }
        } else {
            props.onChange("user_level_tagging", temp_user_level_tagging, index);
        }
    };

    const onKeyDown = (event) => {
        let invalidNumberValues = ["-", "+", "e"];
        if (invalidNumberValues.includes(event.key)) {
            event.preventDefault();
        }
    };

    const { element, tempErrors, index, config } = props;
    return (
        <React.Fragment>
            <div className="app-submit-outer add-incentive-table Progression-table-outer">
                <table>
                    <thead>
                        <tr>
                            <th>User Level</th>
                            <th>Operator</th>
                            <th>{`${element?.parameter_type === "count" ? "" : "Average"} Points`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {element.user_level_tagging &&
                            element.user_level_tagging.map((data, index1) => (
                                <tr key={index1}>
                                    {data.label && <td>{data.label}</td>}
                                    <td>
                                        <fieldset className="single-select">
                                            <div className="material">
                                                <Select
                                                    value={
                                                        data.operator && config.operator.findIndex(({ value }) => value === data.operator.value) > -1
                                                            ? config.operator.filter(({ value }) => value === data.operator.value)
                                                            : null
                                                    }
                                                    onChange={(e) => handleChangeSelect(e, "operator", index, index1)}
                                                    options={config.operator}
                                                    isDisabled={element.is_expired}
                                                    placeholder="Operator*"
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                />

                                                {tempErrors && tempErrors["operator_" + index + "_" + index1] ? (
                                                    <span className="error show">{tempErrors["operator_" + index + "_" + index1]}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </fieldset>
                                    </td>
                                    
                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    onKeyDown={(e) => onKeyDown(e)}
                                                    placeholder=" "
                                                    name={"points_" + index}
                                                    onChange={(e) => handleChange(e, "points", index, index1)}
                                                    className="form-input"
                                                    value={data.points || ''}
                                                    disabled={element.is_expired || false}
                                                />
                                            </div>
                                            {tempErrors && tempErrors["points_" + index + "_" + index1] ? (
                                                <span className="error show">{tempErrors["points_" + index + "_" + index1]}</span>
                                            ) : (
                                                ""
                                            )}
                                        </fieldset>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};
export default AppsSubmittedCard;
