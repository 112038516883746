import React, { useEffect } from "react";
import Select from "react-select";
import { Role } from "../../config/constant";

const ProgressionPointsCard = (props) => {
    useEffect(() => { }, [props.element.user_level_tagging, props.tempErrors, props.config]);

    const handleChangeSelect = (option, key, index, index1) => {
        let temp_user_level_tagging = props.element.user_level_tagging;
        temp_user_level_tagging[index1][key] = option;
        props.onChange("user_level_tagging", temp_user_level_tagging, index);
    };

    const handleChange = (event, name, index, index1) => {
        let { value } = event.target;
        if (value && value.length < 15) {
            let temp_user_level_tagging = props.element.user_level_tagging;
            temp_user_level_tagging[index1][name] = value;
            props.onChange("average_points", temp_user_level_tagging, index);
        }
    };

    const onKeyDown = (event) => {
        let invalidNumberValues = [["-", "+", "e"]];
        if (invalidNumberValues.includes(event.key)) {
            event.preventDefault();
        }
    };

    const { element, tempErrors, index, config } = props;
    return (
        <React.Fragment>
            <div className="app-submit-outer add-incentive-table Progression-table-outer">
                <table>
                    <thead>
                        <tr>
                            <th>{element.role_id && element.role_id === Role["BM"] ? "BM Grading" : "User Level"}</th>
                            <th>Operator</th>
                            <th>Average Points (Last 2 Months)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {element.user_level_tagging &&
                            element.user_level_tagging.map((data, index1) => (
                                <tr key={index1}>
                                    {data.label && <td>{data.label}</td>}
                                    <td>
                                        <fieldset className="single-select">
                                            <div className="material">
                                                <Select
                                                    value={config.operator.filter((item) => data.operator_points && item.value === data.operator_points.value)}
                                                    onChange={(e) => handleChangeSelect(e, "operator_points", index, index1)}
                                                    options={config.operator}
                                                    isDisabled={element.is_expired}
                                                    placeholder="Operator*"
                                                    className="react-select"
                                                    classNamePrefix="react-select"
                                                />

                                                {tempErrors && tempErrors["operator_points_" + index + "_" + index1] ? (
                                                    <span className="error show">{tempErrors["operator_points_" + index + "_" + index1]}</span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </fieldset>
                                    </td>

                                    <td>
                                        <fieldset>
                                            <div className="material">
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    onKeyDown={(e) => onKeyDown(e)}
                                                    placeholder=" "
                                                    name={"average_points" + index}
                                                    onChange={(e) => handleChange(e, "average_points", index, index1)}
                                                    className="form-input"
                                                    value={data.average_points || ""}
                                                    disabled={element.is_expired || false}
                                                />
                                            </div>
                                            {tempErrors && tempErrors["average_points_" + index + "_" + index1] ? (
                                                <span className="error show">{tempErrors["average_points_" + index + "_" + index1]}</span>
                                            ) : (
                                                ""
                                            )}
                                        </fieldset>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};
export default ProgressionPointsCard;
