import React, { useEffect, useState } from 'react';
import { ADMIN_ROLES, Role, RoleId } from '../../config/constant';
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import { useHasRight } from '../hooks/useHasRights';
import { Tooltip } from 'react-tippy';

const UserDetailTable = ({ tableShow, dashboardUserData, currentRoleId, subRoleNameOne, filterData, getProgressionDashboard }) => {
    const [loading, setLoading] = useState(false);

    const [tabelshow, setTabelshow] = useState(tableShow);
    const [tableColSpan, setTableColSpan] = useState(0);
    const [leadData, setLeadData] = useState([]);
    const [approvalHistoryData, setApprovalHistoryData] = useState([]);

    const [openLeadPopup, setOpenLeadPopup] = useState(false);
    const [approvalHistory, setApprovalHistory] = useState(false);
    const { adminRoleId } = useHasRight();


    const hideModalOpenLeadPopup = () => {
        setOpenLeadPopup(false);
        document.body.classList.remove("overflow-hidden");
    }

    const hideApprovalHistoryModel = () => {
        setApprovalHistory(false);
        document.body.classList.remove("overflow-hidden");
    }

    const toggleTable = (rowId, item) => {
        setTabelshow(rowId);
    };

    useEffect(() => {
        getColSpan(currentRoleId)
        setTabelshow(tableShow)
    }, [currentRoleId, tableShow])

    const getColSpan = (role_id) => {
        if (role_id === Role['BM']) {
            setTableColSpan(5)
        } else if (role_id === Role['RBH']) {
            setTabelshow(10)
        } else {
            setTabelshow(9)
        }
    }

    const showLeadButton = (user_obj) => {
        if (user_obj && user_obj.manager_reportees && user_obj.manager_reportees.length > 0) {
            let manager_reportees = user_obj.manager_reportees;
            return manager_reportees.every(item => item.main_status === 1) && [ADMIN_ROLES['master_admin'], ADMIN_ROLES['super_admin']].includes(adminRoleId);
        }
        return false;
    }

    function formatCurrency(num, delimiter) {
        if (num) {
            let numStr = num.toString();
            return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
        }
        return num;
    }

    const handleLeads = async (user_id) => {
        try {
            setLoading(true);
            let response = await MasterService.post('/sfa/user/get_open_leads', { user_id });
            if (response && response.data && response.data.status === 200) {
                if (response.data.data && response.data.data.length > 0) {
                    let response_data = response.data.data;
                    setLeadData(response_data);
                    setLoading(false);
                } else {
                    setLoading(false)
                }
                setOpenLeadPopup(true);
            } else {
                toast.error(response.data.message)
                setLoading(false);
            }
        } catch (error) {
            toast.error(error)
            setLoading(false);
        }
    }

    const approveJourney = async (params) => {
        setLoading(true);
        try {
            let req_params = {
                user_id: params.main_id,
                user_role_id: params.main_role_id,
                is_master_admin: 1
            }
            let response = await MasterService.post('/sfa/user/bulk_users_update', req_params)
            if (response && response.data && response.data.status === 200) {
                getProgressionDashboard(filterData);
                toast.success("Journey Approved");
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    const revertJourney = async (params) => {
        setLoading(true);
        try {
            let req_params = {
                user_id: params.main_id,
                user_role_id: params.main_role_id,
                reportee_id: params.main_id,
                reportee_role_id: params.main_role_id,
                is_master_admin: 1
            }
            let response = await MasterService.post('/sfa/user/revert_journey', req_params)
            if (response && response.data && response.data.status === 200) {
                getProgressionDashboard(filterData);
                toast.success("Journey Reverted");
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    const viewApprovalHistory = async (params) => {
        setLoading(true);
        try {
            let response = await MasterService.post('/sfa/user/journey_approval_history', {user_id: params.main_id, user_role_id: params.main_role_id})
            if (response && response.data && response.data.status === 200) {
                setApprovalHistory(true);
                setApprovalHistoryData(response.data.data);
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }

    }


    const excludeUserFromJourney = async (params) => {
        setLoading(true);
        try {
            let response = await MasterService.post('/sfa/user/exclude_journey_users', { user_id: params.main_id, user_role_id: params.main_role_id })
            if (response && response.data && response.data.status === 200) {
                getProgressionDashboard(filterData);
                toast.success('User successfully removed from Journey')
            } else {
                toast.error(response.data.message)
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div>
                <div className="payout-detail-table">
                    <div className="data-table">
                        <table>
                            <thead>
                                <tr>
                                    {currentRoleId !== Role['BM'] ? <th></th> : null}
                                    <th>SFA ID</th>
                                    <th>User Name</th>
                                    <th>User Count</th>
                                    {currentRoleId !== Role['BM'] ?
                                    <>
                                    <th>BRO and Sr. BRO count</th>                                    
                                    <th>ARO and Sr. ARO count</th></>                                   
                                    :null}
                                    <th>Status</th>
                                    <th>System Deviation</th>
                                    <th>{RoleId[currentRoleId]} Deviation</th>
                                    {[Role['RBH'], Role['BM'], Role['AM']].includes(currentRoleId) ? <th>Actions</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dashboardUserData && dashboardUserData.length > 0 ?
                                        dashboardUserData.map((item, idx) => {
                                            return (
                                                <>
                                                    <tr className={tabelshow === idx ? 'active' : ''}>
                                                        {currentRoleId !== Role['BM'] ? <td onClick={() => toggleTable(idx, item)}>
                                                            <span className="loan-id-arrow"></span>
                                                        </td> : null}
                                                        <td>{item.main_id}</td>
                                                        <td>
                                                            <span className="truncate" title="Test AM 1">
                                                                {item.main_name}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="active-status">{item.user_reportee_count}</span>
                                                        </td>
                                                        {currentRoleId !== Role['BM'] ?
                                                        <>                                                       
                                                        <td>
                                                            <span>{item.bro_count + item.sr_bro_count}</span>
                                                        </td>                                                       
                                                        <td>
                                                            <span>{item.aro_count + item.sr_aro_count}</span>
                                                        </td>
                                                      </>:null}
                                                        <td>
                                                            {item.progression_excluded_by ? (
                                                                <>
                                                                    Journey Excluded{' '}
                                                                    <Tooltip
                                                                        html={(
                                                                            <div className="controlled-example tooltip-main">
                                                                                <div className="tooltip-data tootip-outer">
                                                                                    <ul className="user-detail">
                                                                                        User removed by {item.progression_excluded_by}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        className="tootip-outer"
                                                                        theme="light"
                                                                        size="regular"
                                                                        position="bottom"
                                                                        trigger="click"
                                                                    >
                                                                        <span title="" className="tooltio-ic">
                                                                            <i className='ic-info-icon1'></i>
                                                                        </span>
                                                                    </Tooltip>
                                                                </>
                                                            ) : item.main_status === 1 ? "Approved" : "Pending"}
                                                        </td>
                                                        <td>{item.system_deviation ? formatCurrency(item.system_deviation, '.') : 0}</td>
                                                        <td>{item.recommended_deviation === "NULL" ? 0 : formatCurrency(item.recommended_deviation, '.')}</td>
                                                        <td>

                                                            {currentRoleId === Role['RBH'] && showLeadButton(item) && (
                                                                <ul className='action-btn'>
                                                                    <li>
                                                                        <a class="btn btn-default" href={() => false} onClick={() => { handleLeads(item.main_id) }}>
                                                                            <i class="ic-remove_red_eyevisibility"></i>
                                                                        </a>
                                                                    </li>
                                                                    {item.main_status === 1 ? (
                                                                        <>
                                                                            {item.admin_approval_status === 0 && (
                                                                                <>
                                                                                    <li>
                                                                                        <a class="btn btn-default" href={() => false} onClick={() => { approveJourney(item) }}>
                                                                                            <i class="ic-check"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a class="btn btn-default" href={() => false} onClick={() => { revertJourney(item) }}>
                                                                                            <i class="ic-clearclose"></i>
                                                                                        </a>
                                                                                    </li>
                                                                                </>
                                                                            )}
                                                                            <li>
                                                                                <a class="btn btn-default" href={() => false} onClick={() => { viewApprovalHistory(item) }}>
                                                                                    <i class="ic-Call-history"></i>
                                                                                </a>
                                                                            </li>
                                                                        </>
                                                                    ) : null}
                                                                </ul>
                                                            )}
                                                            {(currentRoleId === Role['BM'] || currentRoleId === Role['AM']) && (
                                                                <li>
                                                                    <button class="btn btn-line" disabled={item.progression_excluded_by !== "" || item.main_status === 1} href={() => false} onClick={() => { excludeUserFromJourney(item) }}>
                                                                        Exclude Journey
                                                                    </button>
                                                                </li>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {tabelshow === idx && item.manager_reportees && item.manager_reportees.length > 0 ? (
                                                        <tr className="subtable" key={idx}>
                                                            <td colSpan="12">
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>SFA ID</th>
                                                                            <th>User Name</th>
                                                                            <th>User Count</th>
                                                                            <th>{`${subRoleNameOne} Status`}</th>
                                                                            <th>System Deviation</th>
                                                                            <th>{`${subRoleNameOne} Deviation`}</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            item.manager_reportees.map((sub_item, sub_idx) => {
                                                                                return (
                                                                                    <tr key={sub_idx}>
                                                                                        <td>{sub_item.main_id}</td>
                                                                                        <td>{sub_item.main_name}</td>
                                                                                        <td>{sub_item.user_reportee_count}</td>
                                                                                        <td>{sub_item.progression_excluded_by ? (
                                                                                            <>
                                                                                                Journey Excluded{' '}
                                                                                                <Tooltip
                                                                                                    html={(
                                                                                                        <div className="controlled-example tooltip-main">
                                                                                                            <div className="tooltip-data tootip-outer">
                                                                                                                <ul className="user-detail">
                                                                                                                    User removed by {sub_item.progression_excluded_by}
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    className="tootip-outer"
                                                                                                    theme="light"
                                                                                                    size="regular"
                                                                                                    position="bottom"
                                                                                                    trigger="click"
                                                                                                >
                                                                                                    <span title="" className="tooltio-ic">
                                                                                                        <i className='ic-info-icon1'></i>
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            </>
                                                                                        ) : sub_item.main_status === 1 ? "Approved" : "Pending"}</td>
                                                                                        <td>{item.system_deviation ? formatCurrency(sub_item.system_deviation, '.') : 0}</td>
                                                                                        <td>{formatCurrency(sub_item.recommended_deviation, '.')}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </>

                                            )
                                        }) :
                                        <tr>
                                            <td colSpan={tableColSpan}>
                                                No Record Found
                                            </td>
                                        </tr>

                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="model-popup-outer role-history-popup">
                    {loading ? <Loader /> : null}
                    <Modal show={openLeadPopup} handleClose={hideModalOpenLeadPopup} >
                        <div className="modal-header">
                            <h2>Open Leads</h2>
                        </div>
                        <div className='modal-body'>
                            <div className='data-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>User ID</th>
                                            <th>Lead ID</th>
                                            <th>Application Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leadData.map(user =>
                                            user.lead_data.map((lead, index) => (
                                                <tr key={index}>
                                                    <td>{index === 0 ? user.user_id : ""}</td>
                                                    <td>{lead.lead_id}</td>
                                                    <td>{lead.application_type}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="model-popup-outer role-history-popup">
                    {loading ? <Loader /> : null}
                    <Modal show={approvalHistory} handleClose={hideApprovalHistoryModel} >
                        <div className="modal-header">
                            <h2>Journey Approval History</h2>
                        </div>
                        <div className='modal-body'>
                            <div className='data-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Journey Month</th>
                                            <th>System Deviation</th>
                                            <th>RBH Deviation</th>
                                            <th>Deviation %</th>
                                            <th>Approved By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            approvalHistoryData && approvalHistoryData.length ? approvalHistoryData.map((item, idx) => {
                                                return <tr key={idx}>
                                                    <td>{item.progression_month_year}</td>
                                                    <td>{item.system_deviation ? item.system_deviation : 0}</td>
                                                    <td>{item.rbh_deviation}</td>
                                                    <td>{item.deviation_percentage} %</td>
                                                    <td>{item.approved_by}</td>
                                                </tr>
                                            }) :
                                                <tr>
                                                    <td colSpan={5}>
                                                        No Record Found
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

const Modal = ({ handleClose, show, children, style = {} }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={style}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default UserDetailTable;
