
import React, { useEffect } from 'react';
const ToggleSwitch = (props) => {
    useEffect(() => {
    }, [props.status])

    const onToggleSwitchChange = () => {
        let { status, is_expired } = props
        status = parseInt(status) === 1 ? true : false
        if (!is_expired) {
            props.onChange("status", !status, false)
        }
    }

    let { status } = props
    status = parseInt(status) === 1 ? true : false
    return (
        <div className='ToggleSwitch ToggleSwitch__rounded'>
            <div className='ToggleSwitch__wrapper'>
                <div className={`Slider ${status && 'isChecked'}`} onClick={onToggleSwitchChange}></div>
            </div>
        </div>
    );
}

export default ToggleSwitch