import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import DateFormat from 'dateformat';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
const OfficeAddress = (props) => {
    const [loading, setLoading] = useState(false);
    const [office_address, setOfficeAddress] = useState({})
    const historyLead = useNavigate();

    useEffect(() => {
        getOfficeAddress()
    }, [])

    const getOfficeAddress = async () => {
        setLoading(true)
        await MasterService.post('/sfa/admin/office_address', {}).then(function (response) {
            if (response.data.status === 200) {
                setOfficeAddress(response.data.data);
            } else {
                toast.error(response.data.message);
            }
        }).catch(function (response) {
        });
        setLoading(false)
    }

    const handleEdit = (element) => {
        // historyLead.push({
        //     pathname: "/edit_office_address",
        //     state: element
        // })
        historyLead("/edit_office_address", { state: element });
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="top-heading d-flex">
                    <h1>Office Addresses</h1>
                </div>
                <div className='user-managemnt-detail-table data-table'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {office_address?.length > 0 && office_address.map((element, index) => (
                                <tr key={index}>
                                    <td>{element.name}</td>
                                    <td>{element.address}</td>
                                    <td>{element.latitude}</td>
                                    <td>{element.longitude}</td>
                                    <td>{DateFormat(element.created_date, 'dd mmm, yyyy')}</td>
                                    <td>{parseInt(element.status) === 1?'Active':'Inactive'}</td>
                                    <td>
                                        <ul class="action-btn">
                                            <li>
                                                <a class="btn btn-default" href={() => false} onClick={() => { handleEdit(element) }}>
                                                    <i class="ic-remove_red_eyevisibility"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment >
    )
}
export default OfficeAddress;